<template>
  <div class="bigScreen1026">
    <div class="data">
      <div class="data-title">
        <div class="title-center fl">溯源可视化大屏</div>
        <div class="top-back" @click="goIndex"><span>返回</span></div>
      </div>
      <div class="data-content">
        <div class="con-left fl">
          <div class="left-top">
            <div class="info">
              <div class="info-title">
                <span>基本信息</span>
              </div>

              <div class="info-main" v-loading="baseInfoLoading">
                <div class="main-list" v-if="screenData != null">
                  <div class="main-list-block">
                    <span class="pz">品种</span>
                    <span class="mc">{{ screenData.csgl.pz }}</span>
                  </div>

                  <div class="main-list-block">
                    <span class="pz">规格</span>
                    <span class="mc">{{ screenData.csgl.gg }}</span>
                  </div>
                  <div class="main-list-block">
                    <span class="pz">采收日期</span>
                    <span class="mc">{{ screenData.csgl.scrq }}</span>
                  </div>
                  <div class="main-list-block">
                    <span class="pz">产地</span>
                    <span class="mc">{{ screenData.csgl.chandi }}</span>
                  </div>
                  <div class="main-list-block">
                    <span class="pz">存储方式</span>
                    <span class="mc">{{ screenData.csgl.ccfs }}</span>
                  </div>
                  <div class="main-list-block">
                    <span class="pz">种植标准</span>
                    <span class="mc">{{ screenData.csgl.zzbz }}</span>
                  </div>
                  <div class="main-list-block">
                    <span class="pz">有机种植面积</span>
                    <span class="mc">{{ screenData.csgl.zzmj }}</span>
                  </div>
                  <div class="main-list-block">
                    <span class="pz">年供应量</span>
                    <span class="mc">{{ screenData.csgl.ngyl }}</span>
                  </div>
                  <div class="main-list-block" style="width: 97%">
                    <span class="pz">产品介绍</span>
                    <span class="mc">{{ screenData.csgl.cpjs }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="top-bottom" v-if="screenData != null">
              <div class="title">
                <span>检测报告</span>
              </div>
              <div class="product-title">
                <span class="product-title-text">产品质量检测报告</span>
                <span class="result">合格</span>
              </div>
              <div class="swiper-card">
                <el-carousel
                    :interval="10000000"
                    type="card"
                    height="156px"
                    arrow="always"
                    indicator-position="none"
                >
                  <el-carousel-item
                      v-for="(item, index) in screenData.csgl.img || []"
                      :key="index"
                  >
                    <div class="card">
                      <img :src="getImgUrl(item)" alt=""/>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="swiper-bg">
                <img src="@/assets/img/jcbgdz.png" alt=""/>
              </div>
            </div>
          </div>
          <div class="left-bottom">
            <div class="title">
              <span>企业资质</span>
            </div>
            <div class="swiper-container" ref="productListRef">
              <div class="swiper-wrapper">
                <div
                    v-for="item in swiperList"
                    :key="item.id"
                    class="swiper-slide"
                >
                  <img :src="getImgUrl(item)" alt=""/>
                  <!-- <div class="qytj-title">{{ item.title }}</div>
                    <div class="qytj-into">ss</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con-center fl">
          <div class="map-num">
            <!-- <el-select v-model="selectVal.qiye" placeholder="请选择企业">
                <el-option value=""></el-option>
                <el-option value=""></el-option>
              </el-select> -->
            <el-select
                v-model="selectVal.product"
                placeholder="请选择产品"
                no-data-text="没有数据"
                style="margin-left: 20px"
                @change="selectProductFn"
            >
              <el-option
                  v-for="(item, index) in selectList"
                  :value="item.uuid"
                  :label="item.cpmc"
                  :key="index"
              ></el-option>
            </el-select>
          </div>
          <div
              class="cen-top"
              id="mapContain"
              style="width: 100%; height: 85%"
          ></div>
          <!--          <div class="map-lengend">
                      <div class="tl">
                        <span>图例</span>
                      </div>
                      <div style="margin-top: 10px">
                        <div class="map-lengend-text">
                          <img src="@/assets/img/cgs.png" alt=""/>
                          <span>采购商</span>
                        </div>
                        <div class="map-lengend-text top">
                          <img src="@/assets/img/scqy.png" alt=""/>
                          <span>生产企业</span>
                        </div>
                      </div>
                    </div>-->
        </div>
        <div class="con-right fr">
          <div class="right-top">
            <div class="title">
              <span>产品溯源档案</span>
            </div>

            <div class="distance-first5">
              <vue-seamless-scroll
                  :data="productList"
                  :class-option="optionHover"
                  class="seamless-warp"
              >
                <div class="distance-swiper">
                  <div
                      class="distance-content"
                      v-for="(item, index) in productList"
                      :key="index"
                      @ScrollEnd="onScrollEnd"
                  >
                    <div class="distance-content-left">
                      <img src="@/assets/img/cpsy.png" alt=""/>
                    </div>
                    <div class="distance-content-right">
                      <div class="distance-content-right-title">
                        <span>{{ item.taskName }}</span>
                        <span class="time">{{ item.sysj.substring(0, 9) }}</span>
                      </div>
                      <div class="distance-content-right-text">
                        <img :src="getImgUrl(item.taskImg)" alt=""/>

                        <div class="distance-content-right-text-info">
                          <div>
                            <span>执行人：{{ item.zxrmc }}</span>
                          </div>
                          <div>
                            <span>电话：{{ item.zxrdh }}</span>
                          </div>
                          <div>
                            <span>投入品：{{ item.inputName }}</span>
                          </div>
                          <div>
                            <span>使用数量：{{ item.sysl }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </vue-seamless-scroll>
              <div v-if="productList.length == 0" class="nodata">
                <span>暂无数据</span>
              </div>
            </div>
          </div>
          <div class="right-center">
            <div class="title"><span>种植基地信息</span></div>
            <div class="zzjdinfo">
              <img src="" alt=""/>
              <div class="zzjdinfo-text">
                <div>种植基地名称：{{ screenData.jdxx.baseName }}</div>
                <div>种植基地址：{{ screenData.jdxx.baseAddress }}</div>
                <div>坐标：{{ screenData.jdxx.centerPos }}</div>
              </div>
            </div>
            <div class="zzjdinfo-base-info">
              <div class="base-info">
                <span>基本信息</span>
                <img src="@/assets/img/111.png" alt=""/>
              </div>
              <div class="base-info">
                <div class="zizhoubg">
                  <img
                      class=""
                      :src="getImgUrl(screenData.jdxx.baseImg[0])"
                      alt=""
                  />
                </div>

                <p>
                  {{ screenData.jdxx.introduce }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import vueSeamlessScroll from "vue-seamless-scroll";
import axios from "axios";
// import  echarts from "echarts";
import * as echarts from "echarts";
import geoJson from "@/assets/geojson/zizhou.json";

export default {
  name: "",
  components: {
    vueSeamlessScroll,
  },
  computed: {
    optionHover() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      swiper: null,
      img: [
        "@/assets/img/cgbj.png",
        "@/assets/img/cgbj.png",
        "@/assets/img/cgbj.png",
        "@/assets/img/cgbj.png",
        "@/assets/img/cgbj.png",
      ],

      selectList: [],
      cpid: "",
      screenData: {
        csgl: {
          //采收信息
          cpid: 0, //产品ID
          cpmc: "", //产品名称
          pz: "", //品种
          gg: "", //规格
          scrq: "", //采收日期，格式：yyyy-MM-dd
          chandi: "", //产地
          cpjs: "", //产品介绍
          ccfs: "", //存储方式
          zzbz: "", //种植标准
          zzmj: 0.0, //种植面积
          ngyl: 0.0, //年供应量
          qyid: 0, //关联企业ID
          qymc: "", //关联企业名称
          jdid: 0, //关联基地ID
          jdmc: "", //关联基地名称
          img: "", //检测报告（多张）
          cgsj: "", //采购商
          uuid: "", //uuid,自动生成
          creator: "", //创建人
          id: 0, //主键ID，新增为0或者不传；修改必传且大于0
          del: 0,
          location: "",
          createTime: "@datetime",
          delUser: "",
          delTime: "@datetime",
        },
        qyxx: {
          //企业信息
          qymc: "", //名称
          qylx: "", //企业类型,数据字典：采购商管理、企业管理
          qyjs: "", //经营介绍,最多500字
          addr: "", //地址,最多100字
          lxr: "", //联系人
          lxrdh: "", //联系方式
          url: "", //网址
          location: "", //经纬度
          img: "", //图片（多张）
          zzzs: "", //资质证书
          baseId: 0, //关联基地ID
          creator: "", //创建人
          id: 0, //主键ID，新增为0或者不传；修改必传且大于0
          del: 0,
          createTime: "@datetime",
          delUser: "",
          delTime: "@datetime",
        },
        jdxx: {
          //基地信息
          baseName: "", //基地名称
          contourArea: 0.0, //基地轮廓面积/㎡ -> 不可更改
          baseArea: 0.0, //基地面积/㎡
          baseAddress: "", //基地地址
          centerPos: "", //地块中心点坐标
          polygonWkt: "", //地块多边形顶点坐标
          basePrincipal: "", //基地负责人
          tel: "", //联系电话
          introduce: "", //基地介绍
          baseImg: "", //基地图片-可传多张,怎么传怎么回显
          brand: "", //关联设备的品牌
          weatherDevice: "", //气象设备号,多个用逗号分割
          relayDevice: "", //灌溉控制设备号,多个用逗号分割
          soilDevice: "", //土壤设备号,多个用逗号分割
          waterDevice: "", //水质设备号,多个用逗号分割
          cameraDevice: "", //摄像头设备号,多个用逗号分割
          insectDevice: "", //虫情设备号,多个用逗号分割
          sporeDevice: "", //孢子设备号,多个用逗号分割
        },
      },
      baseInfoLoading: true,
      uuid: 'f12c88d54c114cb38295f46dfee30604',
      selectVal: {
        qiye: "",
        product: "",
      },
      swiperList: [],
      productList: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getProductList();
      this.getData();
    });
  },
  methods: {
    // 下拉获取uuid
    selectProductFn(value) {
      this.uuid = value;
      this.getData();
      //   cpid查询列表
      let selectedIndex = this.selectList.findIndex(
          (option) => option.uuid == value
      );
      console.log(selectedIndex, "选中的index", value);
      if (selectedIndex != "-1") {
        this.cpid = this.selectList[selectedIndex].cpid;
        this.getProductSuyuan(this.cpid);
        // this.$nextTick(() => {
        //   this.getSwiperDistance();
        // });
      }
    },
    //产品溯源档案列表
    getProductSuyuan(cpid) {
      this.$get("/pro10/nsrw/listTask", {
        cropId: cpid,
      }).then((res) => {
        console.log(res, "产品溯源");
        res.data.data.forEach((element) => {
          element.taskImg = element.taskImg ? JSON.parse(element.taskImg) : [];
        });

        this.productList = res.data.data;
      });
    },
    //下拉列表
    getProductList() {
      this.$get("/pro10/csgl/list").then((res) => {
        if (res.data.data.length > 0) {
          this.selectList = res.data.data;
          this.selectVal.product = res.data.data[0].uuid;
          this.cpid = res.data.data[0].cpid;
          console.log(res, "获取到下拉的数据");
          this.getProductSuyuan(this.cpid);
        }
      });
    },
    // 基本信息
    getData() {
      this.$get("/pro10/csgl/detail/" + this.uuid).then((res) => {
        res.data.data.qyxx.img = res.data.data.qyxx.img
            ? JSON.parse(res.data.data.qyxx.img)
            : [];
        res.data.data.csgl.img = res.data.data.csgl.img
            ? JSON.parse(res.data.data.csgl.img)
            : [];
        res.data.data.qyxx.zzzs = res.data.data.qyxx.zzzs
            ? JSON.parse(res.data.data.qyxx.zzzs)
            : [];
        res.data.data.jdxx.baseImg = res.data.data.jdxx.baseImg
            ? JSON.parse(res.data.data.jdxx.baseImg)
            : [];
        this.screenData = res.data.data;
        this.swiperList = res.data.data.qyxx.zzzs;
        this.baseInfoLoading = false;
        this.getMapJson();
        this.$nextTick(() => {
          this.getSwiper();
        });
      });
    },
    onScrollEnd() {
      this.getProductSuyuan(this.cpid);
    },
    getImgUrl(name) {
      let imgUrl = "http://public.half-half.cn/" + name;
      return imgUrl;
    },

    //加载地图数据
    getMapJson() {
      var myChart = echarts.init(document.getElementById("mapContain"));
      let geoList = [];
      for (let index = 0; index < 20; index++) {
        geoList.push({
          map: "zizhou",
          zoom: 1,
          zLevel: 0,
          roam: false, // 禁止拖拽
          silent: true,
          label: {
            show: false,
          },

          itemStyle: {
            areaColor: "#0c5081",
            shadowColor: "#77cdfe",

            //发光效果

            shadowOffsetY: index * 1.5,
            shadowOffsetX: 0,
          },
        });
      }

      echarts.registerMap("zizhou", geoJson);

      let options = {
        title: {
          text: "",
          subtext: "",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          //提示框组件。
          backgroundColor: "rgba(0, 0, 0, 0)", // 设置 tooltip 背景色为透明
          borderColor: "rgba(0, 0, 0, 0)",
          opacity: 1,
          shadowColor: "rgba(0, 0, 0, 0)", // 设置阴影颜色为透明
          shadowBlur: 0, // 设置阴影模糊度为0，即无阴影
          trigger: "item", //数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
          formatter: function (params) {
            console.log(params);
            if (params.data.lx == "qiye") {
              return `<div class='custom-tooltip-style'>
                      <div class="bjtooltip">
                         <div class="bjtooltip-name"><span style='color:#fff;font-size:13px;'>${params.data.name}</span></div>
                        <div class="bjtooltip-name-text" style="margin:20px;">
                          <div><span class="bjtooltip-text">公司名称2：</span><span class="bjtooltip-text-right">${params.data.name}</span></div>
                          <div><span class="bjtooltip-text">联系人：</span><span class="bjtooltip-text-right">${params.data.lxr}</span></div>
                           <div><span class="bjtooltip-text" >联系电话：</span><span class="bjtooltip-text-right">${params.data.lxrdh}</span></div>
                          <div><span class="bjtooltip-text" >联系地址：</span><span class="bjtooltip-text-right">${params.data.addr}</span></div>
                        </div>
                      </div>
                  </div>`;
            } else {
              return `<div class='custom-tooltip-style'>
                      <div class="bjtooltip1">
                         <div class="bjtooltip1-name"><span style='color:#fff;font-size:13px;'>${params.data.name}</span></div>
                        <div class="span" style="margin:20px;">
                          <div><span class="bjtooltip1-text">公司名称1：</span><span class="bjtooltip1-text-right">${params.data.name}</span></div>
                          <div><span class="bjtooltip1-text">联系人：</span><span class="bjtooltip1-text-right">${params.data.lxr}</span></div>
                           <div><span class="bjtooltip1-text">联系电话：</span><span class="bjtooltip1-text-right">${params.data.lxrdh}</span></div>
                          <div><span class="bjtooltip1-text">联系地址：</span><span class="bjtooltip1-text-right">${params.data.addr}</span></div>
                        </div>
                      </div>
                  </div>`;
            }
          },
          textStyle: {
            fontSize: "24px",
          },
        },
        /*         visualMap: {
                           min: 0,
                           max: 10000,
                           left: 'left',
                           top: 'bottom',
                           text: ['高', '低'], // 文本，默认为数值文本
                           calculable: true
                       },*/
        geo: [...geoList],
        series: [
          {
            type: "scatter",
            coordinateSystem: "geo",
            data: [
              {
                name: this.screenData.qyxx.qymc,
                addr: this.screenData.qyxx.addr, //地址,最多100字
                lxr: this.screenData.qyxx.lxr, //联系人
                lxrdh: this.screenData.qyxx.lxrdh, //联系方式
                lx: "qiye",
                value: this.screenData.qyxx.location
                    ? this.screenData.qyxx.location.split(",")
                    : [],

                symbolSize: [64, 84],
                symbol: "image://http://www.leedong.top:8888/down/cUJV0IZOWtAv",
              },
              {
                name: this.screenData.csgl.cgsj,

                addr: this.screenData.csgl.addr, //地址,最多100字
                lxr: this.screenData.csgl.lxr, //联系人
                lxrdh: this.screenData.csgl.lxrdh, //联系方式
                lx: "cgs",
                symbolSize: [64, 84],
                value: this.screenData.csgl.location
                    ? this.screenData.csgl.location.split(",")
                    : [],
                symbol: "image://http://www.leedong.top:8888/down/62oPhoj8xRHJ",
              },
            ],
          },
        ],
      };
      console.log(this.screenData.csgl.location, "坐标");
      myChart.setOption(options);
    },
    //产品溯源轮播图

    // 返回首页
    goIndex() {
      this.$router.push(window.localStorage.getItem("screenRouter"));
    },
    //产品轮播
    getSwiper() {
      this.swiper = new Swiper(this.$refs.productListRef, {
        loop: true, // 无缝
        autoplay: {
          //自动开始
          delay: 3000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        paginationClickable: true,
        slidesPerView: 2, // 一组三个
        spaceBetween: 10, // 间隔
        mousewheel: true, // 启用鼠标滚轮控制
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
::v-deep .bjtooltip {
  background-image: url(~@/assets/img/cgbj.png);
  width: 300px;
  height: 180px;
  background-size: 100% 100%;
}
::v-deep .el-carousel__item--card {
  width: 30%;
  text-align: center;
  padding-left: 41px;
}
::v-deep .bjtooltip1 {
  background-image: url(~@/assets/img/qytcbj.png);
  width: 300px;
  height: 180px;
  background-size: 100% 100%;
}

::v-deep .el-carousel__mask {
  background: transparent;
}

::v-deep .el-input__inner {
  background-color: #0b1f3d;
  border: 3px solid #274367;
  color: #fff;
  box-shadow: 0 0 1px #046799, 0 0 0px #5a5fbf, 0 0 8px #adceee, 0 0 7px #558fc8;
}

::v-deep .el-carousel__arrow--left {
  top: 70px;
  left: 3px;
  font-size: 15px;
  color: #02e9ff;
  background-image: url(~@/assets/img/left-icon.png);
  background-size: 100% 100%;
}

//右箭头
::v-deep .el-carousel__arrow--right {
  top: 70px;
  right: 3px;
  font-size: 15px;
  background-image: url(~@/assets/img/right-icon.png);
  background-size: 100% 100%;
  color: #02e9ff;
}
::v-deep .bjtooltip-text {
  color: #fff;
  font-size: 12px;
}
::v-deep .bjtooltip-name {
  padding: 15px 0 0 50px;
}
::v-deep .bjtooltip-name1 {
  padding: 5px 0 0 50px;
}
::v-deep .bjtooltip-text-right {
  color: #e0d470;
  font-size: 12px;
}
::v-deep .bjtooltip1-text-right {
  color: #5f98a0;
  font-size: 13px;
}
::v-deep .bjtooltip1-text1 {
  color: #c48a15;
  font-size: 13px;
}
.bigScreen1026 {
  height: 100%;
  background: url("~@/assets/img/map_bg.png");
  background-size: 100% 100%;
  min-width: 1366px;

  .data {
    width: 100%;
    height: 100%;
  }

  .data > .data-title {
    width: 100%;
    position: relative;
    height: 105px;
    padding: 15px 0 20px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    .top-back {
      position: absolute;
      right: 40px;
      text-align: center;
      color: #fff;
      line-height: 30px;
      top: 40px;
      width: 80px;
      padding-left: 5px;
      background-size: 100% 100%;

      height: 30px;
      background: url("~@/assets/img/fanhui.png") no-repeat;
      span {
        font-size: 13px;
      }
    }
  }

  .data > .data-title > .title-center {
    font-size: 34px;
    color: #fff;
  }

  .data > .data-content {
    width: 98%;
    height: calc(100% - 105px);
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    margin: auto;
  }

  .data > .data-content > .con-left {
    width: 25.4375%;
    height: 100%;
  }

  .data > .data-content > .con-left > .left-top {
    width: 100%;
    height: calc(75% - 20px);

    margin-top: -20px;
    position: relative;
  }

  img {
    border: 0;
    vertical-align: middle; /*  去掉图片底部默认的3像素空白缝隙*/
  }

  body {
    font-family: Microsoft YaHei, Arial, "\5b8b\4f53";
  }

  .clearfix:before,
  .clearfix:after {
    content: "";
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  .clearfix {
    *zoom: 1; /*IE/7/6*/
    /*兼容IE6下的写法*/
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .data > .data-content > .con-left > .left-top > .info {
    height: 45%;
    width: 100%;

    /*border-radius: 4px;*/
    box-sizing: border-box;
    position: relative;
  }

  .data > .data-content > .con-left > .left-top > .info > .info-title {
    width: 100%;
    height: 43px;
    background: url("~@/assets/img/info-title.png") no-repeat;
    position: absolute;
    top: -22px;
    background-size: 100% 100%;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    line-height: 43px;
    text-align: left;

    span {
      padding: 0 0 10px 30px;

      display: inline-block;
    }
  }

  .data > .data-content > .con-left > .left-top > .info > .info-main {
    width: 100%;
    height: 80%;

    .main-list {
      display: flex;
      width: 100%;
      height: 100%;
      flex-wrap: wrap;
      padding-top: 30px;

      .main-list-block {
        width: 48%;
        margin: 2px;

        height: 38px;
        background: url("~@/assets/img/kuang.png") no-repeat;
        background-size: 100% 100%;

        display: flex;
        justify-content: space-between;
        line-height: 38px;

        .pz {
          color: #fff;
          font-size: 13px;
          font-weight: 600;
          padding-left: 15px;
        }

        .mc {
          color: #fff;
          font-size: 13px;
          font-weight: 600;
          padding-right: 15px;
        }
      }
    }
  }

  .data > .data-content > .con-left > .left-top > .info > .info-main > div {
    width: 275px;
    height: 25%;
    margin: 8px auto;
  }

  .data > .data-content > .con-left > .left-top > .top-bottom {
    height: calc(57% - 20px);
    width: 100%;
    box-sizing: border-box;
    position: relative;

    .title {
      width: 100%;
      height: 43px;
      background: url("~@/assets/img/info-title.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      line-height: 43px;
      text-align: left;

      span {
        padding: 0 0 10px 10px;

        display: inline-block;
      }
    }

    .product-title {
      height: 80px;
      width: 100%;
      background: url("~@/assets/img/cpzl.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .product-title-text {
        color: #b9c2d1;
        font-size: 17px;
        line-height: 80px;
        padding-left: 65px;
        display: inline-block;
      }

      .result {
        color: #56ad64;
        font-size: 18px;
        line-height: 80px;
        font-weight: 700;
      }
    }

    .swiper-card {
      width: 100%;

      .card {
        width: 50%;

        margin: 10px;
        height: 140px;
        min-height: 140px;
        img {
          height: 100%;
        }
      }
    }

    .swiper-bg {
      position: absolute;
      bottom: 10px;

      img {
        width: 100%;
      }
    }
  }

  .data > .data-content > .con-left > .left-bottom {
    width: 100%;
    height: 25%;

    box-sizing: border-box;
    position: relative;

    .title {
      width: 100%;
      height: 43px;
      background: url("~@/assets/img/info-title.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      line-height: 43px;
      text-align: left;

      span {
        padding: 0 0 10px 10px;

        display: inline-block;
      }
    }

    .swiper-slide {
      background: url("~@/assets/img/qith.png") no-repeat;
      background-size: 100% 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .qytj-title {
        font-size: 15px;
        color: #fff;
        padding-left: 15px;
      }

      .qytj-into {
        font-size: 14px;
        color: #bcacac;
        padding-left: 15px;
      }

      img {
        width: 90%;
        padding-left: 10px;
        height: 90px;
      }
    }
  }

  .data > .data-content > .con-center {
    width: 49.125%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

  }

  .data > .data-content > .con-center > .map-num {
    width: 100%;
    height: 120px;
    position: absolute;
    top: 15px;
    text-align: left;
    z-index: 1000;
    display: flex;
    justify-content: center;
  }

  .map-lengend {
    background: url("~@/assets/img/tuli.png");
    background-size: 100% 100%;
    width: 120px;
    position: absolute;
    bottom: 60px;
    height: 100px;

    .tl {
      text-align: center;
      font-size: 13px;
      color: #02e9ff;
      padding-top: 8px;
    }

    .map-lengend-text {
      display: flex;
      color: #02e9ff;
      font-size: 11px;
      padding-left: 10px;
      padding-top: 3px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .data > .data-content > .con-center > .cen-top {
    width: 100%;
    height: calc(75% - 20px);
    margin-bottom: 20px;
    /*background-color: rgba(0,24,106,0.3);*/
    /*border: 1px solid #0089ff;*/
    /*border-radius: 4px;*/
    /*box-sizing: border-box;*/
  }

  .data > .data-content > .con-center > .cen-bottom {
    width: 100%;
    height: 25%;

    box-sizing: border-box;
    position: relative;
  }

  .data > .data-content > .con-right {
    width: 25.4375%;
    height: 100%;
    min-width: 25.4375%;
    margin-top: -20px;
  }

  .data > .data-content > .con-right > .right-top {
    width: 100%;
    height: 60%;

    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    .title {
      width: 100%;
      height: 43px;
      background: url("~@/assets/img/info-title.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      line-height: 43px;
      position: absolute;
      top: -18px;
      text-align: left;
      z-index: 10000;

      span {
        padding: 0 0 10px 10px;

        display: inline-block;
      }
    }

    .distance-first5 {
      width: 100%;
      //height: 460px;
      overflow: hidden;
      position: absolute;
      top: 25px;
      z-index: 1000;

      .distance-swiper {
        width: 100%;
        height: 100%;
        // overflow: hidden;

        .distance-content {
          width: 100%;
          margin: auto;
          display: flex;
          height: 178px !important;
          justify-content: space-between;
          margin-top: 10px;

          .distance-content-left {
            margin-top: 15px;
            height: 177px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .distance-content-right {
            background: url("~@/assets/img/cpsybg.png") no-repeat;
            background-size: 100% 100%;
            height: 175px;
            width: 90%;
            margin: auto;
            margin-top: 15px;

            .distance-content-right-title {
              display: flex;
              justify-content: space-between;
              font-size: 15px;
              color: #fff;
              padding-left: 15px;
              padding-top: 5px;
              padding-right: 15px;
              font-weight: 600;

              .time {
                color: #cabbbb;
                font-weight: 400;
              }
            }

            .distance-content-right-text {
              display: flex;
              height: calc(158px - 55px);
              font-size: 15px;
              color: #fff;
              padding-left: 15px;
              padding-top: 15px;
              padding-right: 15px;

              img {
                height: 100%;
                width: 150px;
              }

              .distance-content-right-text-info {
                font-size: 14px;
                color: #c1c5b9;
                padding-left: 10px;

                div {
                  padding-top: 5px;
                }
              }
            }
          }
        }
      }
      .nodata {
        text-align: center;
        padding-top: 20%;
        font-size: 15px;
        color: #02e9ff;
      }
    }
  }

  .data > .data-content > .con-right > .right-center {
    width: 100%;
    height: calc(40% - 20px);
    margin: 18px 0;

    box-sizing: border-box;
    position: relative;

    .title {
      width: 100%;
      height: 43px;
      background: url("~@/assets/img/info-title.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      line-height: 43px;

      text-align: left;

      span {
        padding: 0 0 10px 10px;

        display: inline-block;
      }
    }

    .zzjdinfo {
      background: url("~@/assets/img/zzjdbg.png") no-repeat;
      background-size: 100% 100%;
      height: 108px;
      display: flex;

      width: 100%;

      .zzjdinfo-text {
        color: #fff;
        font-size: 13px;
        padding-left: 23%;
        line-height: 30px;
      }
    }

    .zzjdinfo-base-info {
      .base-info {
        display: flex;
        width: 100%;

        span {
          color: #4eaab9;
          font-size: 13px;
        }

        .zizhoubg {
          height: 100px;
          min-width: 160px;
          padding-top: 10px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        p {
          height: 100px;
          overflow: hidden;
          color: #fff;
          font-size: 13px;

          padding-left: 10px;
        }
      }
    }
  }

  .data > .data-content > .con-right > .right-bottom {
    width: 100%;
    height: 20%;
    background-color: rgba(0, 24, 106, 0.5);
    border: 1px solid #20558b;
    box-sizing: border-box;
    position: relative;
  }

  .data > .data-content .title {
    height: 35px;
    line-height: 35px;
    width: 100%;
    color: #fff;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
  }

  .data > .data-content .charts {
    width: 100%;
    height: calc(100% - 35px);
  }

  .data > .data-content img.bj-1 {
    position: absolute;
    left: -1px;
    top: -1px;
  }

  .data > .data-content img.bj-2 {
    position: absolute;
    right: -1px;
    top: -1px;
  }

  .data > .data-content img.bj-3 {
    position: absolute;
    right: -1px;
    bottom: -1px;
  }

  .data > .data-content img.bj-4 {
    position: absolute;
    left: -1px;
    bottom: -1px;
  }
}
</style>
  